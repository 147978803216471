<template>
  <MainLayout :title="$t('account')" :short-title="$t('account')" back-to="/" hide-avatar>
    <template v-slot:menu>
      <AccountMenu />
    </template>

    <template v-slot:content>
      <div class="panel">
        <div class="panel-body">
          <div class="hero-user horizontal">
            <div class="hero-avatar">
              <AccountAvatar :show-edit="true"></AccountAvatar>
            </div>
            <div class="hero-content">
              <h1 class="title is-2">
                {{ user.full_name }}
              </h1>
              <p class="email">{{ user.email }}</p>
              <p class="is-hidden-mobile">
                <router-link to="/account/personal">{{ $t('personalDataEdit') }}</router-link>
              </p>
              <nav class="has-margin-top-medium has-text-weight-bold">
                <b-button type="is-black" outlined size="is-small" @click="$router.push('/logout')">
                  {{ $t('login.signout') }}
                </b-button>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <nav class="nav-mobile has-margin-top-large is-hidden-tablet">
        <router-link to="/account/personal">
          <span>{{ $t('personalData') }}</span>
          <faicon icon="chevron-right"></faicon>
        </router-link>
        <router-link to="/account/password" class="hidden">
          <span>{{ $t('password') }}</span>
          <faicon icon="chevron-right"></faicon>
        </router-link>
        <router-link to="/account/sessions" class="hidden">
          <span>{{ $t('sessions') }}</span>
          <faicon icon="chevron-right"></faicon>
        </router-link>
      </nav>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout'

import AccountAvatar from '@/components/account/AccountAvatar'
import AccountMenu from '@/components/menus/AccountMenu'

export default {
  components: {
    MainLayout,
    AccountAvatar,
    AccountMenu,
  },
  data() {
    return {
      user: this.$store.getters.getUser,
    }
  },
}
</script>
